// brands list - home page
import sephoraLogo from './../src/assets/images/brands/sephora.svg'
import nikeLogo from './../src/assets/images/brands/nike.svg'
import gucciLogo from './../src/assets/images/brands/gucci.svg'
import louisVuittonLogo from './../src/assets/images/brands/louis-vuitton.svg'
import burberryLogo from './../src/assets/images/brands/burberry.svg'
import zaraLogo from './../src/assets/images/brands/zara.svg'
import bloomingDalesLogo from './../src/assets/images/brands/blooming-dales.svg'
import jCrewLogo from './../src/assets/images/brands/j-crew.svg'

// trending brands logos - member home page
import targetLogo from './../src/assets/images/brands/target.svg'
import revolveLogo from './../src/assets/images/brands/revolve.svg'
import motherDenimLogo from './../src/assets/images/brands/mother-denim.svg'
import gapLogo from './../src/assets/images/brands/gap.svg'
import barnesAndNoblesLogo from './../src/assets/images/brands/barnes-and-nobles.svg'
import goopLogo from './../src/assets/images/brands/goop.svg'
import pacsunLogo from './../src/assets/images/brands/pacsun.svg'
import UGGLogo from './../src/assets/images/brands/UGG.svg'
import kennethColeLogo from './../src/assets/images/brands/kenneth-cole.svg'
import luckyBrandLogo from './../src/assets/images/brands/lucky-brand.svg'
import athletaLogo from './../src/assets/images/brands/athleta.svg'
import intermixLogo from './../src/assets/images/brands/intermix.svg'
import oakleyLogo from './../src/assets/images/brands/oakley.svg'
import adidasLogo from './../src/assets/images/brands/adidas.svg'
import wayfairLogo from './../src/assets/images/brands/wayfair.svg'
import zadigAndVoltaireLogo from './../src/assets/images/brands/zadigAndVoltaire.svg'
import sonosLogo from './../src/assets/images/brands/sonos.svg'
import theRealRealLogo from './../src/assets/images/brands/theRealReal.svg'
import arhausLogo from './../src/assets/images/brands/arhaus.svg'
import walgreensLogo from './../src/assets/images/brands/walgreens.svg'

// fashion brands logos - member home page
import nordstormLogo from './../src/assets/images/brands/fashion/nordstorm.svg'
import urbanOutfittersLogo from './../src/assets/images/brands/fashion/urban-outfitters.svg'
import lacosteLogo from './../src/assets/images/brands/fashion/lacoste.svg'
import kateSpadeLogo from './../src/assets/images/brands/fashion/kate-spade.svg'

// beauty brands logos - member home page
import beautyCounterLogo from './../src/assets/images/brands/beauty/beauty-counter.png'
import benefitLogo from './../src/assets/images/brands/beauty/benefit.svg'
import sigmaBeautyLogo from './../src/assets/images/brands/beauty/sigma-beauty.svg'
import dysonLogo from './../src/assets/images/brands/beauty/dyson.svg'
import stilaLogo from './../src/assets/images/brands/beauty/stila.svg'

// home brands logos - member home page
import containerStoreLogo from './../src/assets/images/brands/home/container-store.svg'
import oneKingsLaneLogo from './../src/assets/images/brands/home/one-kings-lane.svg'
import kitchenAidLogo from './../src/assets/images/brands/home/kitchen-aid.svg'
import homeDepotLogo from './../src/assets/images/brands/home/home-depot.svg'

// lifestyle brands logos - member home page
import walmartLogo from './../src/assets/images/brands/lifestyle/walmart.svg'
import CVSPharmacyLogo from './../src/assets/images/brands/lifestyle/cvs-pharmacy.svg'
import bestBuyLogo from './../src/assets/images/brands/lifestyle/best-buy.svg'
import QVCLogo from './../src/assets/images/brands/lifestyle/QVC.svg'
import lululemonLogo from './../src/assets/images/brands/lululemon.svg'
import jamesperseLogo from './../src/assets/images/brands/james-perse.png'
import skimsLogo from './../src/assets/images/brands/skims.jpg'
import potteryBarn from './../src/assets/images/brands/pottery-barn.svg'
import anthropologieLogo from '../src/assets/images/brands/trendingBrands/anthropologie.svg'
import canva from '../src/assets/images/brands/trendingBrands/canva-1.svg'
import therealreal from '../src/assets/images/brands/trendingBrands/the-realreal-seeklogo.com.svg'
import target from '../src/assets/images/brands/trendingBrands/target.svg'
import neimanMarcus from '../src/assets/images/brands/trendingBrands/Neiman_Marcus-Logo.wine.svg'
import freepeople from '../src/assets/images/brands/trendingBrands/Free People Icon Logo.svg'
import farfetch from '../src/assets/images/brands/trendingBrands/farfetch.svg'
import cocoon from '../src/assets/images/brands/trendingBrands/Cocoon by Sealy.svg'

export const trendingBrandsLogos = [
  {
    id: '59720_2092',
    src: targetLogo,
  },
  {
    id: '2423467',
    src: revolveLogo,
  },
  {
    id: '4942550',
    src: nikeLogo,
  },
  {
    id: '40776',
    src: motherDenimLogo,
  },
  {
    id: '396110_5553',
    src: gapLogo,
  },
  {
    id: '4258829',
    src: barnesAndNoblesLogo,
  },
  {
    id: '43728',
    src: UGGLogo,
  },
  {
    id: '4964921',
    src: kennethColeLogo,
  },
  {
    id: '396113_5556',
    src: athletaLogo,
  },
  {
    id: '1237',
    src: nordstormLogo,
  },
  {
    id: '1463156',
    src: oakleyLogo,
  },
  {
    id: '225976_4270',
    src: adidasLogo,
  },
  {
    id: '97526_2835',
    src: sigmaBeautyLogo,
  },
  {
    id: '5206455',
    src: wayfairLogo,
  },
  {
    id: '37723',
    src: zadigAndVoltaireLogo,
  },
  {
    id: '5253058',
    src: lacosteLogo,
  },
  {
    id: '2954255',
    src: sonosLogo,
  },
  {
    id: '2568723',
    src: walgreensLogo,
  },
  {
    id: '355678_5118',
    src: goopLogo,
  },
  {
    id: '1244771_8471',
    src: arhausLogo,
  },
  {
    id: '1225650_8270',
    src: theRealRealLogo,
  },
  {
    id: '4836691',
    src: CVSPharmacyLogo,
  },
  {
    id: '1213441_8154',
    src: homeDepotLogo,
  },
  // {
  //   id: '1011l241',
  //   src: lululemonLogo,
  // },
  {
    id: '39476',
    src: jamesperseLogo,
  },
  {
    id: '1441803_10056',
    src: skimsLogo,
  },
  {
    id: 'IMPCT_4332',
    src: potteryBarn,
  },
  {
    id: '43176',
    src: urbanOutfittersLogo,
  },
  {
    id: '13867',
    src: bloomingDalesLogo,
  },
  {
    id: '1355298_9368',
    src: QVCLogo,
  },
  {
    id: 'IMPCT_9368',
    src: QVCLogo,
  },
  {
    id: '37353',
    src: containerStoreLogo,
  },
  {
    id: '39789',
    src: anthropologieLogo,
  },
  {
    id: 'IMPCT_10068',
    src: canva,
  },
  {
    id: 'IMPCT_8270',
    src: therealreal,
  },
  {
    id: 'IMPCT_2092',
    src: target,
  },
  {
    id: '25003',
    src: neimanMarcus,
  },
  {
    id: '43177',
    src: freepeople,
  },
  {
    id: '1011l121',
    src: farfetch,
  },
  {
    id: 'IMPCT_13725',
    src: cocoon,
  },
]

export const fashionBrandsLogos = [
  {
    id: '1011l241',
    src: lululemonLogo,
  },
  {
    id: '1237',
    src: nordstormLogo,
  },
  {
    id: '43176',
    src: urbanOutfittersLogo,
  },
  {
    id: '5253058',
    src: lacosteLogo,
  },
  {
    id: '42004',
    src: kateSpadeLogo,
  },
  {
    id: '1441803_10056',
    src: skimsLogo,
  },
]

export const beautyBrandsLogos = [
  {
    name: 'beauty counter',
    src: beautyCounterLogo,
    cashback: '0%',
    link: 'https://www.beautycounter.com/',
  },
  {
    id: '24765',
    src: benefitLogo,
  },
  {
    id: '97526_2835',
    src: sigmaBeautyLogo,
  },
  {
    id: '36310',
    src: dysonLogo,
  },
  {
    id: '3009921',
    src: stilaLogo,
  },
]

export const homeBrandsLogos = [
  {
    id: '37353',
    src: containerStoreLogo,
  },
  {
    id: '4867369',
    src: oneKingsLaneLogo,
  },
  {
    id: '1845109',
    src: kitchenAidLogo,
  },
  {
    id: '1213441_8154',
    src: homeDepotLogo,
  },
]

export const lifestyleBrandsLogos = [
  {
    id: '2149',
    src: walmartLogo,
  },
  {
    id: '4836691',
    src: CVSPharmacyLogo,
  },
  {
    name: 'best buy',
    src: bestBuyLogo,
    cashback: '0%',
    link:
      'https://corporate.bestbuy.com/best-buys-private-label-business-turns-15/',
  },
  {
    id: '1355298_9368',
    src: QVCLogo,
  },
]
